import {useState} from 'react';

import type {EarlyWarningEventComment} from '@onroadvantage/onroadvantage-api';

import {useEventReviewContext} from '../contexts/EventReviewContext';

const REASON_CODE_ANSWERED = 'Contact answered';
const REASON_CODE_UNANSWERED = 'Contact did not answer';

export interface DeletableComment {
  [key: symbol]: number;
  text: string;
  commentTime: Date;
}
export const useNotificationContacts = ({
  eventDetailsComments,
}: {
  eventDetailsComments?: EarlyWarningEventComment[];
}) => {
  const {setNotificationComments, notificationComments} =
    useEventReviewContext();
  const [comment, setComment] = useState<string>('');
  const [showReasonCodeDropDown, setShowReasonCodeDropDown] =
    useState<boolean>(false);

  const handleCallTriggered = (contactName: string) => {
    setNotificationComments([
      ...notificationComments,
      {
        text: `Call triggered for ${contactName}`,
        commentTime: new Date(),
        [Symbol('iterator')]: 1,
      },
    ]);

    setShowReasonCodeDropDown(true);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() !== '') {
      setNotificationComments([
        ...notificationComments,
        {
          text: comment,
          commentTime: new Date(),
          [Symbol('iterator')]: notificationComments.length + 1,
        },
      ]);
      setComment('');
    }
  };

  const handleReasonCode = (reason: string | null) => {
    if (reason == null) {
      return;
    }

    const oppositeReasons: Record<string, string> = {
      [REASON_CODE_ANSWERED]: REASON_CODE_UNANSWERED,
      [REASON_CODE_UNANSWERED]: REASON_CODE_ANSWERED,
    };

    if (reason.trim() !== '') {
      const filteredComments = notificationComments.filter(
        (submittedComment) => {
          return submittedComment.text !== oppositeReasons[reason];
        },
      );

      setNotificationComments([
        ...filteredComments,
        {
          text: reason,
          commentTime: new Date(),
          [Symbol('iterator')]: filteredComments.length + 1,
        },
      ]);
    }
  };

  const handleCommentDelete = (selectedComment: DeletableComment) => {
    const symbolKey = Object.getOwnPropertySymbols(selectedComment)[0];
    const commentSymbolValue = selectedComment[symbolKey];

    setNotificationComments((prev) =>
      prev.filter((c) => {
        const currentSymbolKey = Object.getOwnPropertySymbols(c)[0];
        return c[currentSymbolKey] !== commentSymbolValue;
      }),
    );
  };

  const commentsList = eventDetailsComments ?? notificationComments;

  return {
    comment,
    setComment,
    handleCallTriggered,
    handleCommentSubmit,
    commentsList,
    handleCommentDelete,
    handleReasonCode,
    REASON_CODE_ANSWERED,
    REASON_CODE_UNANSWERED,
    showReasonCodeDropDown,
  };
};
