import {DateTime} from 'luxon';

export const formatDateTime = (dateString?: string) => {
  if (dateString == null) return 'Unknown';
  const date = DateTime.fromISO(dateString);
  return date.isValid ? date.toFormat('dd/MM/yyyy HH:mm') : 'Unknown';
};

export const formatDriverName = (
  firstName?: string,
  lastName?: string,
): string => {
  const trimmedFirst = firstName?.trim() ?? '';
  const trimmedLast = lastName?.trim() ?? '';

  if ((trimmedFirst + trimmedLast).trim() === '') return 'Unknown';
  return `${trimmedFirst} ${trimmedLast}`.trim();
};

export const formatSpeed = (speed?: number): string => {
  if (speed === undefined || Number.isNaN(speed)) return 'Unknown';
  return `${Math.floor(speed)}km/h`;
};
